import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a className="email" href="mailto:contact@telugu-news.in">
            contact@telugu-news.in
          </a>
          <span className="email">|</span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span>|</span>
          <p>© 2024 www.telugu-news.in</p>
          <span>|</span>
          <p>All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
